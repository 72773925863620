import * as React from "react";
import WebsiteLayout from "../layouts/WebsiteLayout";

const Security = (props) => {
  return (
    <main>
        <WebsiteLayout location={props.location} title="Security">
        <section className="content-header content-header-1 -blue">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="heading">Security</h1>
                </div>
            </div>
        </div>
    </section>
    <section className="content content-2 -bg-grey">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <h2>Application Layer Security</h2>

                    <li>All communications use HTTPS between the browser and client devices. HTTP connections are not supported and will return a permanent redirect to the HTTPS equivalent.</li>
                    <li>DJ Manager accounts are protected by a secure login.</li>
                    <li>Users can only be added to the platform by existing users with Administrator level access.</li>
                    <li>Users can only be removed from the platform by existing users with Administrator level access.</li>
                    <li>Requests to DJ Manager applications and APIs require users to be authenticated.</li>
                    <li>Requests are subject to the following validation checks
                        <ol style={{listStyleType: 'lower-alpha', paddingBottom: 0}}>
                            <li style={{ marginLeft: '2em' }}>The token is valid and has not expired.</li>
                            <li style={{ marginLeft: '2em' }}>The user has access to the tenant they are requesting data for/trying to update.</li>
                            <li style={{ marginLeft: '2em' }}>The user has the appropriate privilege for the operation they are performing.</li>
                            <li style={{ marginLeft: '2em', paddingBottom: 0 }}>The request is not a Cross Site Request.</li>
                        </ol>
                    </li>
                    
                    <h2>Security and Coding Practices</h2>
                    <li>We adhere to OWASP Secure Coding Practices:</li>
                    <h3>Incoming Data</h3>
                    
                    <li>Incoming data is validated on a trusted system (the server) before the data is processed, including expected value, data range, data types</li>
                    <li>Incoming requests are validated to ensure they are coming from a trusted source.</li>
                    <li>Dangerous incoming requests that include hazardous characters are rejected where appropriate.</li>

                    <h3>Outgoing Data</h3>

                    <li>Outgoing data is sanitized to ensure no confidential data is returned.</li>
                    <li>Data is contextually encoded as it is returned.</li>

                    <h3>Authentication and User Management</h3>

                    <li>Authentication is required for all pages, except those specifically required to have public access.</li>
                    <li>Authentication is always enforced by the server.</li>
                    <li>Authentication systems always fail securely.</li>
                    <li>Passwords are encrypted at rest.</li>
                    <li>Authentication is only validated when all input has been completed.</li>
                    <li>Authentication failure does not reveal which part of the data was incorrect.</li>
                    <li>Passwords are not transmitted to the user via email (except initial credentials)</li>
                    <li>Passwords are obstructed on the user's screen e.g web forms have input type of 'password'</li>
                    <li>Passwords can only be reset by users of same user level or above, and a reset password is sent to the user with a secure and unique token.</li>

                    <h3>Session Management</h3>

                    <li>Session Management uses the server's framework session controls</li>
                    <li>Sessions are only created on the server</li>
                    <li>Session domain and path for cookies are restricted appropriately</li>
                    <li>Sessions are terminated both client and server side when the user logs out</li>
                    <li>Logout is available from all pages that are protected by authorization.</li>
                    <li>Session identifiers are not returned in urls, error messages/logs, only in cookies.</li>

                    <h3>Access Control</h3>

                    <li>Authorization is standardised against the DJ Manager environment.</li>
                    <li>Access controls all fail securely.</li>
                    <li>Application access is denied if the application cannot access the security information to validate a user</li>
                    <li>Authorization is enforced for every request</li>
                    <li>Users, application data, functions are restricted to authroized users.</li>

                    <h3>Cryptographic Practices</h3>

                    <li>All cryptographic processed are performed on the server.</li>
                    <li>The latest framework cryptographic procedures are used.</li>

                    <h3>Error Handling/Logging</h3>

                    <li>Sensitive information is not disclosed on error messages.</li>
                    <li>All application errors return a generic error message to the user.</li>
                    <li>Logging is implemented server side.</li>
                    <li>Sensitive information is not stored in logs, such as session identifiers or passwords.</li>
                    <li>Logs are only accessible to authorized individuals within the DJ Manager team.</li>
                    <li>All system exceptions are logged.</li>

                    <h3>Data Protection</h3>

                    <li>Sensitive data is encrypted in transit and at rest.</li>
                    <li>Server side code is not available to download by the user.</li>
                    <li>Sensitive data is not included in HTTP GET requests e.g. Passwords</li>
                    <li>Server access is only permitted by authorized DJ Manager Employees.</li>

                    <h3>Communication Security</h3>

                    <li>DJ Manager where possible enforces that clients use TLS for protection of data in transit.</li>

                    <li>Failed TLS connections do not fallback to insecure connections.</li>
                    <li>All authentication mechanisms require TLS.</li>
                    <li>Sensitive data is always transmitted using TLS.</li>

                    <h3>System Configuration</h3>

                    <li>Servers, frameworks and system components always use the latest approved/stable version.</li>
                    <li>Servers, frameworks and system components have all critical and high priority patches installed
                        ASAP once verification has taken place, other patches are installed alongside platform updates.</li>
                    <li>Development, Testing, Staging and Production environments are isolated.</li>
                    <li>All changes to code are recorded internally and a sanitised user friendly version is made publicly available with each version release.</li>

                    <h3>System Monitoring</h3>

                    <li>DJ Manager uses <a href="https://newrelic.com/">New Relic</a> and <a href="https://sentry.io">Sentry</a> to monitor the infrastructure and
                        application performance, any alerts are sent to our development and infrastructure team.</li>

                    <h3>System Backup and Restoration</h3>

                    <li>Databases are backed up on a daily basis and stored in a protected and isolated storage environment.</li>
                    <li>Databases backups and restorations are regularly tested to ensure that in the event of a system failure, the solution can be recovered.</li>
                    <li>A backup of the production database is always taken prior to platform updates.</li>

                </div>
            </div>
        </div>
    </section>
        </WebsiteLayout>
    </main>
  )
}

export default Security;